import $ from 'jquery'
import InstructionVideoController from './lib/instruction-video-controller'

export default function initInstructionVideoControllers (
  youtubeFrameWorkManager
) {
  $('.instruction-video').each((i, el) => {
    const youtubeId = $(el).attr('data-youtube-id')

    const instructionVideoController = new InstructionVideoController({
      element: el,
      youtubeId: youtubeId,
      youtubeFrameWorkManager: youtubeFrameWorkManager
    })
    instructionVideoController.init()

    const elementId = $(el).attr('id')
    console.log('id', elementId)
    if (elementId !== '') {
      $('[data-instruction-video-id="' + elementId + '"]').each((i, el) => {
        console.log('found', el)
        if (el.hasAttribute('data-instruction-video-seekto')) {
          const seekToValue = $(el).attr('data-instruction-video-seekto')
          const seconds = parseInt(seekToValue)
          $(el).click(() => {
            console.log('seekTo', instructionVideoController, seconds)
            instructionVideoController.seekTo(seconds)
            return false
          })
        }
      })
    }
  })
}
