import 'es6-shim'
import $ from 'jquery'
import initInstructionVideoControllers from './init-instruction-video-controllers'

import YoutubeFrameworkManager from './lib/youtube-framework-wrapper'

const youtubeFrameWorkManager = new YoutubeFrameworkManager()
youtubeFrameWorkManager.init()

$(() => {
  initInstructionVideoControllers(youtubeFrameWorkManager)
})
