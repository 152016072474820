// import $ from 'jquery'
// import objectFitPolyfill from './object-fit-polyfill'
// import EventEmitter from 'eventemitter3'

let YoutubeFrameworkManager = function (options) {
  const _this = this

  /* state */
  let _YT = null
  let _loaded = false
  let _beforeLoadQueue = []

  /* public */
  _this.init = function () {
    // _settings = $.extend({}, options)

    _loadLibrary()
  }

  /* private */
  function _loadLibrary () {
    window.onYouTubeIframeAPIReady = function () {
      _YT = window.YT
      _notifyLoaded()
    }

    window.onPlayerReady = _onPlayerReady

    var tag = document.createElement('script')
    tag.src = 'https://www.youtube.com/iframe_api'
    document.head.appendChild(tag)
  }

  /* private */
  function _onPlayerReady (event) {
    event.target.playVideo()
  }

  /* private */
  function _notifyLoaded () {
    _loaded = true
    _executeQueue()
  }

  /* private */
  function _executeQueue () {
    if (_loaded) {
      while (_beforeLoadQueue.length > 0) {
        const func = _beforeLoadQueue.shift()
        func(_YT)
      }
    }
  }

  /* private */
  function _enqueue (func) {
    _beforeLoadQueue.push(func)
  }

  /* public */
  _this.executeWithYT = function (func) {
    _enqueue(func)
    _executeQueue()
  }
}

export default YoutubeFrameworkManager
